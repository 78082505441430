import {on, fire} from 'delegated-events';

on('carousel:navigate', '.sw-teasercarousel__cnt', (event: any): void => {
  const {target, detail} = event;
  const slides: Array<HTMLElement> = Array.from(
    target.getElementsByClassName('sw-teasercarousel__item')
  );
  const actionEl: HTMLDivElement = target.getElementsByClassName(
    'sw-teasercarousel__actions'
  )[0];
  const max: number = +actionEl.dataset.max;
  const index: number = ((index) => {
    if (index < 0) return max - 1;
    else if (index >= max) return 0;

    return index;
  })(detail.index);

  actionEl.setAttribute('data-current', '' + (index + 1));

  slides.forEach((slide) => {
    slide.setAttribute('aria-hidden', 'true');
  });

  slides[index].removeAttribute('aria-hidden');
});

on('click', '.sw-teasercarousel__btn', function (): void {
  const buttonEl = <HTMLButtonElement>this;
  const actionEl: HTMLDivElement = buttonEl.closest(
    '.sw-teasercarousel__actions'
  );
  const current: number = +actionEl.dataset.current - 1;

  fire(actionEl.closest('.sw-teasercarousel__cnt'), 'carousel:navigate', {
    index:
      +current +
      (buttonEl.classList.contains('sw-teasercarousel__btn--prev') ? -1 : 1),
  });
});
